@import "../../variables";
@import "@dev/zenith/dist/index.css";

.recipe-container {
 display: flex;
 align-items: center;
 gap: 8px;
 flex-wrap: wrap;
 flex-direction: row;
}

.how-to-use-screenshot {
  margin-top: 16px;
  margin-bottom: 16px;
}

.product-samples-cards {
  margin-bottom: 16px;
  min-height:fit-content !important;
}

.recipe-card {
  display: flex; 
  align-items: left; 
  flex-direction: column;
  .recipe-picture {
    margin-bottom: 8px;
  }
  .recipe-description {
    line-height:1.2;
    padding-top:8px;
    padding-bottom: 8px;
    font-size: 12;
  }

}
@media screen and (max-width: $mobile-max-width) {
  .recipe-container {
    flex-direction: column;
   }
}