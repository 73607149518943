@import "../variables";

// Scrollbar styling - Track & handle hover not required thus not defined
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Scrollbar corner */
::-webkit-scrollbar-corner {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-color;
    border-radius: 8px;
}

.text--large {
    font-size: $font-size-x-large;
    margin: 0;
}

.text--medium {
    font-size: $font-size-large;
}

img {
    max-width: 100%;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    .recipe-picture {
        display:flex;
        object-fit: contain;
        overflow: auto;
    }
}


// Page-specific styles
.page {
    display: flex;
    height: 100%;
    width: 100%;
}

.landing-page {
    margin-top: 58px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: $text-landing-page;

    img {
        border: none;
    }

    &__left {
        width: 454px;
        display: flex;
    }

    &__right {
        width: 716px;
    }

    &__product-buttons-row {
        display: flex;
    }

    &__product-button {
        width: 336px;
        height: 188px;
        margin: 4px;
        transition: box-shadow 0.3s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        outline: 1px solid $button-outline;
        background: $button-bg;
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.04);

        &:hover {
            outline: 1px solid $button-outline;
            background: $button-bg;
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
        }

        &:focus,
        &:active {
            border-radius: 4px;
            outline: 2px solid $button-outline--focus;
            background: $button-bg--focus;
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.2);
        }
    }

    &__text-container {
        margin-right: 8px;

        .subtitle p {
            margin-block-end: 0;
        }
    }
}

.methods {
    &__method-title {
        display: flex;
        justify-content: space-between;
    }

    &__view-button {
        text-decoration: none;
    }
}

.objects {
    &__object-title {
        display: flex;
        justify-content: space-between;
    }

    &__view-button {
        text-decoration: none;
    }
}

.landing-page__text-container {
    margin-right: 8px;
}

.landing-page__text-container .subtitle p {
    margin-block-end: 0px;
}

ul {
    margin-block-start: 0px;
}

.table-container {
    border-radius: $border-radius;
    border: 1px solid $border-color;
    margin: 0;
    padding: 0;
    overflow: auto;
    display: inline-block;
    max-width: 100%;

    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: auto;
        text-align: left;

        thead {
            tr {
                margin: 12px;

                th {
                    position: relative;
                    padding: 20px 12px 20px 14px;

                    &:not(:last-child)::after {
                        content: "";
                        position: absolute;
                        top: 12px;
                        bottom: 12px;
                        left: 100%;
                        width: 1px;
                        background-color: $border-color;
                    }
                }
            }
        }

        tbody {
            tr:last-child {
                border-bottom: none;
            }

            td {
                padding: 18px 12px;
                vertical-align: middle;
            }
        }

        tr {
            border-bottom: 1px solid $border-color;
        }
    }
}

.paragraph {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $text-secondary;
}

.title {
    font-weight: 300;
    font-size: 28px;
    line-height: 36px;
    margin-block-start: 8px;
    margin-block-end: 0px;
}

.subtitle {
    font-weight: 300;
    font-size: $font-size-large;
    line-height: 28px;
}

h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

a {
    color: $text-hyperlink;
    text-decoration: underline;
}

code {
    color: $code-color;
    background-color: $code-bg;
    padding: 4px;
}

pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
}

.grayBackground {
    background-color: $backgrounds-content-0;
}

.code-samples-pages__button-container {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    margin: 5px 0;
}

.error-page__main-container {
    height: 100%;
    overflow-y: hidden;
}

.error-page__content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 30px;
}

.error-page__404-content {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

.reference-accordion {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

@media screen and (max-width: $mobile-max-width) {
    .recipe-picture {
        width: 100%; 
        height: auto; 
        max-height: 100%; 
        object-fit: contain;
    }

    .title {
        font-size: $font-size-large;
    }

    .subtitle {
        font-size: $font-size-medium;
    }

    .title,
    .subtitle {
        line-height: 24px;
        font-weight: 400;
    }

    .landing-page {
        flex-direction: column;
        margin-top: 0px;
        margin-bottom: 32px;

        &__left {
            width: 336px;
        }

        &__button-container{
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        &__product-buttons-row {
            flex-direction: column;
            align-items: center;
            gap: 8px;
        }

        &__product-button {
            height: 105px;
            padding: 8px;
            margin: 0px;
        }

        &__text-container {
            padding: 8px 12px;
            margin: 16px 0px;
        }

        ul {
            margin-block-end: 0px;
            padding-left: 24px;
        }

        p:not(:first-child) {
            margin-block-start: 0px;
        }
    }
}